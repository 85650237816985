class RouteBuilder {
  /**
   * Build route by params.
   *
   * @param route - route, as example `project/:id/overview/summary`
   * @param params - params, as example `{id: 333}`
   * @param parameters - parameters which will be added in the end of url after ? mark
   *                      it can be string or array
   * @returns {string} - result route, as example base route
   *                     and params`project/333/overview/summary`
   */
  static build(route, params, parameters) {
    let url = Object.keys(params).reduce((resultRoute, paramKey) => {
      const preparedKey = `:${paramKey}`;
      return resultRoute.includes(preparedKey)
        ? resultRoute.replace(preparedKey, params[paramKey])
        : resultRoute;
    }, route);
    const url_parameters = parameters
      ? '?' +
        Object.entries(parameters)
          .filter((value) => (Array.isArray(value[1]) ? value[1].length : value[1]))
          .map((value) =>
            Array.isArray(value[1])
              ? value[1].map((item) => `${value[0]}=${item}`).join('&')
              : `${value[0]}=${encodeURIComponent(value[1])}`
          )
          .join('&')
      : '';
    return url + url_parameters;
  }
}

export default RouteBuilder;
