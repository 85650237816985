import { useEffect } from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import { ProtectedRoute } from '@components/ProtectedRoute';

import { PageLayout } from '@containers/PageLayout';
import { Login } from '@pages/Login';
import { Graph } from '@pages/Graph';
import ErrorBoundary from '@components/ErrorBoundary';

import { useUserAuth } from 'stores/useUserAuth';

const queryClient = new QueryClient();

const App = () => {
  const { user, isAuthorized, authorize } = useUserAuth();

  useEffect(() => {
    authorize();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      {isAuthorized !== null && (
        <Router>
          <Routes>
            <Route path="/graph" element={<PageLayout />}>
              <Route
                index
                element={
                  <ProtectedRoute redirectPath="/login" isAllowed={!!user && !!isAuthorized}>
                    <ErrorBoundary>
                      <Graph />
                    </ErrorBoundary>
                  </ProtectedRoute>
                }
              />
              <Route
                path=":id"
                element={
                  <ProtectedRoute redirectPath="/login" isAllowed={!!user && !!isAuthorized}>
                    <Graph />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Navigate to="/graph" replace />} />
          </Routes>
        </Router>
      )}
    </QueryClientProvider>
  );
};

export default App;
