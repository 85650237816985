import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';

import { Button } from '@mui/material';

const CustomNode = memo(({ data, Template = null }) => {
  const Inner = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <p>{`Title: ${data.title}`}</p>
        <p>{`File date: ${data.file}`}</p>
        <p>{`App. No.: ${data.applicationNumber}`}</p>
        {data.onClick && <Button onClick={(e) => data.onClick(e)}>See details</Button>}
      </div>
    );
  };

  return (
    <div className="customNode">
      <Inner />
      <Handle type="source" position={Position.Top} id="a" />
      <Handle type="source" position={Position.Right} id="b" />
      <Handle type="source" position={Position.Bottom} id="c" />
      <Handle type="source" position={Position.Left} id="d" />
    </div>
  );
});

export default CustomNode;

CustomNode.displayName = 'Custom ReactFlow Node';
