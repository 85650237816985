import { useQuery } from '@tanstack/react-query';

import { useUserAuth } from 'stores/useUserAuth';

const getAssetUSFamily = async ({ payload, token }) => {
  const response = await fetch(
    `https://api.dev.ipediadata.com/v1/patent_details/${payload}/us_family`,
    {
      method: 'GET',
      headers: {
        Authorization: token,
        Accept: 'application/json'
      }
    }
  );
  const data = await response.json();

  return data;
};

const getPatentData = async ({ payload, token }) => {
  const response = await fetch(
    `https://api.dev.ipediadata.com/v1/patent_details/${payload}/patent_by_ucid`,
    {
      method: 'GET',
      headers: {
        Authorization: token,
        Accept: 'application/json'
      }
    }
  );
  const data = await response.json();

  return data;
};

export const usePatentDataQuery = (payload) => {
  const {
    user: { token }
  } = useUserAuth();

  return useQuery(['patentDetails', payload], () => getPatentData({ payload, token }), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: false
  });
};

export const useAssetsUSFamilyQuery = (payload) => {
  const {
    user: { token }
  } = useUserAuth();

  return useQuery(['assets', payload], () => getAssetUSFamily({ payload, token }), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: false
  });
};
