import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';

import { authenticationService } from '../../services/authentificationService';

import { useUserAuth } from 'stores/useUserAuth';

import './Form.scss';

export const Login = () => {
  const [state, setState] = useState({ email: '', password: '', error: '', stayIn: false });

  const navigate = useNavigate();
  const history = useLocation();

  const { login, error: authError, isAuthorized } = useUserAuth();

  useEffect(() => {
    if (isAuthorized) {
      navigate('/graph');
    }
  }, [isAuthorized]);

  const dismissError = () => setState((state) => ({ ...state, error: '' }));

  const handleEmailChanged = (e) => {
    setState((state) => ({
      ...state,
      email: e.target.value
    }));
  };

  const handlePassChanged = (e) => {
    setState((state) => ({
      ...state,
      password: e.target.value
    }));
  };

  const handleStayInChanged = (e) => {
    setState((state) => ({
      ...state,
      stayIn: e.target.checked
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { email, password, stayIn } = state;

    if (!email) {
      return setState((state) => ({ ...state, error: 'Email is required' }));
    }

    if (!password) {
      return setState((state) => ({ ...state, error: 'Password is required' }));
    }

    login({ email, password, stayIn });
  };

  const { email, password, stayIn, error } = state;

  return (
    <div className="login-page-container">
      <div className="login-form-container">
        <div className="login-form-inner">
          <div className="login-form-fields-container">
            <span className="sign-in-label">Sign in</span>
            <form onSubmit={handleSubmit} className="login-action-form">
              <div className="pull-down-4"></div>
              {error && (
                <div className="login-error-notification-container">
                  <div className="login-error-info">
                    <span className="error-notification-icon">
                      <i className="far fa-times-circle" />
                    </span>
                    <span className="error-text">{error}</span>
                  </div>
                  <span onClick={dismissError} className="error-notification-dismiss-icon">
                    <i className="fas fa-times" />
                  </span>
                </div>
              )}
              <div className="pull-down-4">
                <span className="login-email-label">Email</span>
                <div className="login-email-input-container">
                  <span>
                    <i className="fas fa-envelope" />
                  </span>
                  <input
                    value={email}
                    onChange={handleEmailChanged}
                    type="text"
                    placeholder="Please enter your e-mail address"
                  />
                </div>
              </div>
              <div className="pull-down-5">
                <span className="login-password-label">Password</span>
                <div className="login-password-input-container">
                  <span>
                    <i className="fas fa-key" />
                  </span>
                  <input
                    value={password}
                    onChange={handlePassChanged}
                    type="password"
                    placeholder="Enter password"
                  />
                </div>
              </div>
              <div className="pull-down-6">
                <label className="checkbox-container">
                  Stay signed in
                  <input type="checkbox" checked={stayIn} onChange={handleStayInChanged} />
                  <span className="checkbox-checkmark" />
                </label>
              </div>
              <button className="login-button" type="submit">
                Submit
              </button>
              <a className="forgot-password-link">Forgot password?</a>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
