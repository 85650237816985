import {
  CPC_DESCRIPTIONS,
  CPC_DESCRIPTION,
  CRAWL_SITE_CREATE_ENDPOINT,
  CRAWL_SITE_ENDPOINT,
  DYNAMIC_TAMPLATES_ENDPOINT,
  ELASTIC_MTERMVECTORS_ENDPOINT,
  ELASTIC_SEARCH_ENDPOINT,
  ELASTIC_FAMILIES_ENDPOINT,
  EXPORTS_DOWNLOAD_SUBENDPOINT,
  EXPORTS_ENDPOINT,
  IFI_SEARCH_ENDPOINT,
  IMPORT_JOBS_ENDPOINT,
  JOBS_LIST_SUBENDPOINT,
  JOBS_LIST_DOWNLOAD_SUBENDPOINT,
  MATCHING_ARCHIVE_REQUESTS_RESULTS_ENDPOINT,
  MATCHING_EXECUTE_REQUEST_ENDPOINT,
  MATCHING_REQUEST_CREATE_ENDPOINT,
  MATCHING_REQUEST_DETAILS_ENDPOINT,
  MATCHING_REQUEST_TARGETS_ENDPOINT,
  MATCHING_REQUESTS_RESULTS_ENDPOINT,
  MATCHING_RERUN_REQUEST_ENDPOINT,
  ORG_USERS_ENDPOINT,
  PATENT__ENDPOINT,
  PATENT__PDF_SUBENDPOINT,
  PATENT__CLAIMS_SUBENDPOINT,
  PATENT_ART_TIMELINE_DATA_SUBENDPOINT,
  PATENT_REVIEW_EOUS_ENDPOINT,
  PATENT_REVIEW_TARGETS_ENDPOINT,
  PATENT_REVIEWS_ENDPOINT,
  POWER_RANKING_CPC_CLASS_SUMMARIES_ENDPOINT,
  POWER_RANKING_ENDPOINT,
  PROJECT_ADD_MEMBER_SUBENDPOINT,
  PROJECT_ARCHIVE_SUBENDPOINT,
  PROJECT_CHANGE_OWNER_SUBENDPOINT,
  PROJECT_CITE_ASSIGNEE_DETAIL_SUBENDPOINT,
  PROJECT_CITE_DOWNLOAD_ENDPOINT,
  PROJECT_CITE_ENDPOINT,
  PROJECT_COMPARISONS_ENDPOINT,
  PROJECT_COMPARISONS_PROJECT_ENDPOINT,
  PROJECT_COMPARISONS_SUBENDPOINT,
  PROJECT_DOWNLOAD_MISSING_FAMILY_MEMBERS,
  PROJECT_DYNAMIC_SUBPROJECTS_SUBENDPOINT,
  PROJECT_FAMILIES_SUBENDPOINT,
  PROJECT_IMPORT_REPORT_SUBENDPOINT,
  PROJECT_PATENTS_SUBENDPOINT,
  PROJECT_REMOVE_MEMBER_SUBENDPOINT,
  PROJECT_REMOVE_PATENTS_SUBENDPOINT,
  PROJECT_TARGET_COMPARISONS_SUBENDPOINT,
  PROJECT_UNARCHIVE_SUBENDPOINT,
  PROJECTS_ENDPOINT,
  SESSIONS_ENDPOINT,
  TARGETS_ENDPOINT,
  TOP_LIST_GROUPS_SUBENDPOINT,
  TOP_LIST_SUBCLASSES_SUBENDPOINT,
  UPDATE_PASSWORD_ENDPOINT,
  MATCHING_REQUESTS_ENDPOINT,
  MATCHING_RESULT_BY_UCID,
  MATCHED_RESULTS_ENDPOINT,
  PROJECT_CPC_SUBCLASSES_SUBENDPOINT,
  MATCH_RATING_ENDPOINT,
  PROJECT_CPC_GROUPS_SUBENDPOINT,
  PATENT_DETAILS,
  PATENT_DETAILS_BIBLIOGRAPHY,
  PATENT_DETAILS_SPECIFICATIONS,
  PATENT_DETAILS_IMAGES,
  PATENT_DETAILS_LEGAL_EVENTS,
  PATENT_DETAILS_REFERENCES,
  PATENT_DETAILS_FORWARD_CITES,
  PATENT_DETAILS_PATENT_FAMILY,
  PATENT_DETAILS_EXTENDED_FAMILY,
  PATENT_DETAILS_PTAB,
  PATENT_DETAILS_ASSIGNEE_HISTORY,
  PATENT_DETAILS_PATENT_BY_UCID,
  PATENT_DETAILS_SCORES,
  PATENT_DETAILS_CITES,
  REPORTS_ENDPOINT,
  REPORT_PRODUCTS_COUNTS_SUBENDPOINT,
  REPORT_ASSERTION_COUNTS_SUBENDPOINT,
  REPORT_EOU_COUNTS_SUBENDPOINT,
  REPORT_PROSECUTION_COUNTS_SUBENDPOINT,
  REPORT_TARGETS_PRODUCTS_BAR_SUBENDPOINT,
  REPORT_FAMILIES_ASSERTION_COUNTS_SUBENDPOINT,
  PATENT__SCORE_STATUS_SUBENDPOINT,
  PATENT_LITIGATION,
  PATENT_LITIGATION_INFO,
  USERS_ENDPOINT,
  ORGANIZATIONS_ENDPOINT
} from './constants';

import config from '../conf/config';
import RouteBuilder from '../helpers/routeBuilder';

let projects = (id) => {
  const projectsEndpoint = `${config.apiUrl}${PROJECTS_ENDPOINT}/${id}`;

  const citesEndpoint = `${projectsEndpoint}${PROJECT_CITE_ENDPOINT}`;
  let cite = (ucid) => {
    return {
      GET: RouteBuilder.build(citesEndpoint, {}, { ucid: ucid }),

      assignee: (assignee) => {
        const citesCosAssigneeEndpoint = `${citesEndpoint}/${assignee}`;

        return {
          GET: RouteBuilder.build(citesCosAssigneeEndpoint, {}, { ucid: ucid }),

          detail: (family_id) => {
            return {
              GET: RouteBuilder.build(
                `${citesCosAssigneeEndpoint}${PROJECT_CITE_ASSIGNEE_DETAIL_SUBENDPOINT}/${family_id}`,
                {},
                { ucid: ucid }
              )
            };
          }
        };
      }
    };
  };

  const citeDownloadEndpoint = `${projectsEndpoint}${PROJECT_CITE_DOWNLOAD_ENDPOINT}`;
  let citeDownload = (ucid, assignee) => {
    return {
      GET: RouteBuilder.build(citeDownloadEndpoint, {}, { ucid: ucid, assignee_name: assignee })
    };
  };

  let families = (page, per, order, field, filter) => {
    return {
      GET: RouteBuilder.build(
        `${projectsEndpoint}${PROJECT_FAMILIES_SUBENDPOINT}`,
        {},
        { page, per, order, field, filter }
      )
    };
  };
  let familiesById = (family_id) => {
    return {
      GET: `${projectsEndpoint}${PROJECT_FAMILIES_SUBENDPOINT}/${family_id}`
    };
  };

  let result = {
    changeOwner: {
      POST: `${projectsEndpoint}${PROJECT_CHANGE_OWNER_SUBENDPOINT}`
    },
    addMember: {
      POST: `${projectsEndpoint}${PROJECT_ADD_MEMBER_SUBENDPOINT}`
    },
    removeMember: {
      DELETE: `${projectsEndpoint}${PROJECT_REMOVE_MEMBER_SUBENDPOINT}`
    },
    downloadMissingFamilyMembers: {
      GET: `${projectsEndpoint}${PROJECT_DOWNLOAD_MISSING_FAMILY_MEMBERS}`
    },
    removePatents: {
      DELETE: `${projectsEndpoint}${PROJECT_REMOVE_PATENTS_SUBENDPOINT}`
    },
    archive: {
      PUT: `${projectsEndpoint}${PROJECT_ARCHIVE_SUBENDPOINT}`
    },
    unarchive: {
      PUT: `${projectsEndpoint}${PROJECT_UNARCHIVE_SUBENDPOINT}`
    },
    patents: {
      GET: `${projectsEndpoint}${PROJECT_PATENTS_SUBENDPOINT}`
    },
    importReport: {
      GET: `${projectsEndpoint}${PROJECT_IMPORT_REPORT_SUBENDPOINT}`
    },
    cite,
    citeDownload,
    families,
    familiesById,
    comparisons: {
      GET: `${projectsEndpoint}${PROJECT_COMPARISONS_SUBENDPOINT}`
    },
    targetComparisons: {
      GET: `${projectsEndpoint}${PROJECT_TARGET_COMPARISONS_SUBENDPOINT}`
    },
    dynamicSubprojects: {
      POST: `${projectsEndpoint}${PROJECT_DYNAMIC_SUBPROJECTS_SUBENDPOINT}`
    }
  };
  result['PUT'] = projectsEndpoint;
  result['GET'] = projectsEndpoint;

  return result;
};
projects['GET'] = `${config.apiUrl}${PROJECTS_ENDPOINT}`;
projects['POST'] = `${config.apiUrl}${PROJECTS_ENDPOINT}`;

let importJobs = (id) => {
  const importJobsEndpoint = `${config.apiUrl}${IMPORT_JOBS_ENDPOINT}/${id}`;

  let result = {
    list: {
      GET: `${importJobsEndpoint}${JOBS_LIST_SUBENDPOINT}`,
      download: {
        GET: `${importJobsEndpoint}${JOBS_LIST_DOWNLOAD_SUBENDPOINT}`
      }
    }
  };
  result['GET'] = importJobsEndpoint;

  return result;
};
importJobs['GET'] = `${config.apiUrl}${IMPORT_JOBS_ENDPOINT}`;
importJobs['POST'] = `${config.apiUrl}${IMPORT_JOBS_ENDPOINT}`;

let targets = (id) => {
  return {
    DELETE: `${config.apiUrl}${TARGETS_ENDPOINT}/${id}`,
    PUT: `${config.apiUrl}${TARGETS_ENDPOINT}/${id}`
  };
};
targets['POST'] = `${config.apiUrl}${TARGETS_ENDPOINT}`;

let patentReviews = (ucid) => {
  return {
    GET: `${config.apiUrl}${PATENT_REVIEWS_ENDPOINT}/${ucid}`,
    PUT: `${config.apiUrl}${PATENT_REVIEWS_ENDPOINT}/${ucid}`
  };
};

let patent = (ucid) => {
  const patentEndpoint = `${config.apiUrl}${PATENT__ENDPOINT}${ucid}`;

  return {
    pdf: {
      GET: `${patentEndpoint}${PATENT__PDF_SUBENDPOINT}`
    },
    artTimelineData: {
      GET: `${patentEndpoint}${PATENT_ART_TIMELINE_DATA_SUBENDPOINT}`
    },
    claims: (claimNumber) => ({
      GET: `${patentEndpoint}${PATENT__CLAIMS_SUBENDPOINT}/${claimNumber}`
    }),
    scoreStatus: {
      GET: `${patentEndpoint}${PATENT__SCORE_STATUS_SUBENDPOINT}`
    }
  };
};

let patentDetails = (ucid) => {
  return {
    bibliography: {
      GET: `${config.apiUrl}${PATENT_DETAILS}/${ucid}${PATENT_DETAILS_BIBLIOGRAPHY}`
    },
    claims: {
      GET: `${config.apiUrl}${PATENT_DETAILS}/${ucid}${PATENT__CLAIMS_SUBENDPOINT}`
    },
    specifications: {
      GET: `${config.apiUrl}${PATENT_DETAILS}/${ucid}${PATENT_DETAILS_SPECIFICATIONS}`
    },
    forward_cites: {
      GET: `${config.apiUrl}${PATENT_DETAILS}/${ucid}${PATENT_DETAILS_FORWARD_CITES}`
    },
    references: {
      GET: `${config.apiUrl}${PATENT_DETAILS}/${ucid}${PATENT_DETAILS_REFERENCES}`
    },
    legal_events: {
      GET: `${config.apiUrl}${PATENT_DETAILS}/${ucid}${PATENT_DETAILS_LEGAL_EVENTS}`
    },
    patent_family: {
      GET: `${config.apiUrl}${PATENT_DETAILS}/${ucid}${PATENT_DETAILS_PATENT_FAMILY}`
    },
    extended_family: {
      GET: `${config.apiUrl}${PATENT_DETAILS}/${ucid}${PATENT_DETAILS_EXTENDED_FAMILY}`
    },
    images: {
      GET: `${config.apiUrl}${PATENT_DETAILS}/${ucid}${PATENT_DETAILS_IMAGES}`
    },
    ptab: {
      GET: `${config.apiUrl}${PATENT_DETAILS}/${ucid}${PATENT_DETAILS_PTAB}`
    },
    assignee_history: {
      GET: `${config.apiUrl}${PATENT_DETAILS}/${ucid}${PATENT_DETAILS_ASSIGNEE_HISTORY}`
    },
    patent_by_ucid: {
      GET: `${config.apiUrl}${PATENT_DETAILS}/${ucid}${PATENT_DETAILS_PATENT_BY_UCID}`
    },
    scores: {
      GET: `${config.apiUrl}${PATENT_DETAILS}/${ucid}${PATENT_DETAILS_SCORES}`
    },
    cites: {
      GET: `${config.apiUrl}${PATENT_DETAILS}/${ucid}${PATENT_DETAILS_CITES}`
    },
    litigation: {
      GET: `${config.apiUrl}${PATENT_DETAILS}/${ucid}${PATENT_LITIGATION}`
    },
    litigationInfo: {
      POST: `${config.apiUrl}${PATENT_DETAILS}/${PATENT_LITIGATION_INFO}`
    }
  };
};

let familiesForReport = (project_id) => {
  return {
    GET: `${config.apiUrl}/${REPORTS_ENDPOINT}/${project_id}${PROJECT_FAMILIES_SUBENDPOINT}`
  };
};

let reportCharts = (project_id) => {
  return {
    productsCount: {
      GET: `${config.apiUrl}${REPORTS_ENDPOINT}/${project_id}${REPORT_PRODUCTS_COUNTS_SUBENDPOINT}`
    },
    assertionCounts: {
      GET: `${config.apiUrl}${REPORTS_ENDPOINT}/${project_id}${REPORT_ASSERTION_COUNTS_SUBENDPOINT}`
    },
    prosecutionCounts: {
      GET: `${config.apiUrl}${REPORTS_ENDPOINT}/${project_id}${REPORT_PROSECUTION_COUNTS_SUBENDPOINT}`
    },
    eouCounts: {
      GET: `${config.apiUrl}${REPORTS_ENDPOINT}/${project_id}${REPORT_EOU_COUNTS_SUBENDPOINT}`
    },
    targetsAndProductsBar: {
      GET: `${config.apiUrl}${REPORTS_ENDPOINT}/${project_id}${REPORT_TARGETS_PRODUCTS_BAR_SUBENDPOINT}`
    },
    familiesAssertionCounts: {
      GET: `${config.apiUrl}${REPORTS_ENDPOINT}/${project_id}${REPORT_FAMILIES_ASSERTION_COUNTS_SUBENDPOINT}`
    }
  };
};

let cpcClassSummaries = (major_class) => {
  return {
    GET: `${config.apiUrl}${POWER_RANKING_CPC_CLASS_SUMMARIES_ENDPOINT}/${major_class}`
  };
};

let comparisons = (id) => {
  const comparisonsEndpoint = `${config.apiUrl}${PROJECT_COMPARISONS_ENDPOINT}`;
  return {
    POST: comparisonsEndpoint,
    GET: `${comparisonsEndpoint}/${id}`,
    DELETE: `${comparisonsEndpoint}/${id}`
  };
};

let getProjectsForComparison = () => {
  const comparisonsEndpoint = `${config.apiUrl}${PROJECT_COMPARISONS_PROJECT_ENDPOINT}`;
  return {
    GET: `${comparisonsEndpoint}`
  };
};

let exports = (id) => {
  const exportsEndpoint = `${config.apiUrl}${EXPORTS_ENDPOINT}/${id}`;

  return {
    GET: exportsEndpoint,
    DELETE: exportsEndpoint,
    download: {
      GET: `${exportsEndpoint}${EXPORTS_DOWNLOAD_SUBENDPOINT}`
    }
  };
};

let patentReviewEous = (id) => {
  const patentReviewEousEndpoint = `${config.apiUrl}${PATENT_REVIEW_EOUS_ENDPOINT}`;

  return {
    POST: patentReviewEousEndpoint,
    PUT: `${patentReviewEousEndpoint}/${id}`,
    DELETE: `${patentReviewEousEndpoint}/${id}`,
    GET: `${patentReviewEousEndpoint}/${id}`
  };
};

exports['GET'] = `${config.apiUrl}${EXPORTS_ENDPOINT}`;
exports['POST'] = `${config.apiUrl}${EXPORTS_ENDPOINT}`;

export const endpoints = {
  sessions: {
    GET: `${config.apiUrl}${SESSIONS_ENDPOINT}`
  },
  users: {
    GET: `${config.apiUrl}${ORG_USERS_ENDPOINT}`
  },
  organizations: {
    GET: `${config.apiUrl}${ORGANIZATIONS_ENDPOINT}`
  },
  userInfo: {
    GET: (id) => `${config.apiUrl}${USERS_ENDPOINT}/${id}`,
    PUT: (id) => `${config.apiUrl}${USERS_ENDPOINT}/${id}`,
    POST: `${config.apiUrl}${USERS_ENDPOINT}`
  },
  updatePassword: {
    PATCH: `${config.apiUrl}${UPDATE_PASSWORD_ENDPOINT}`
  },
  search: {
    POST: `${config.apiUrl}${IFI_SEARCH_ENDPOINT}`
  },
  dynamicTamplates: {
    GET: `${config.apiUrl}${DYNAMIC_TAMPLATES_ENDPOINT}`
  },
  projects,
  patent,
  patentDetails,
  familiesForReport,
  importJobs,
  elasticSearch: {
    POST: `${config.apiUrl}${ELASTIC_SEARCH_ENDPOINT}`, // Search API
    mtermvectors: {
      POST: `${config.apiUrl}${ELASTIC_MTERMVECTORS_ENDPOINT}` // Multi termvectors API
    },
    families: {
      POST: `${config.apiUrl}${ELASTIC_FAMILIES_ENDPOINT}` // Search API
    }
  },
  targets,
  patentReviews,
  patentReviewTargets: {
    POST: `${config.apiUrl}${PATENT_REVIEW_TARGETS_ENDPOINT}`,
    DELETE: (id) => `${config.apiUrl}${PATENT_REVIEW_TARGETS_ENDPOINT}/${id}`
  },
  patentReviewEous,
  powerRanking: {
    POST: `${config.apiUrl}${POWER_RANKING_ENDPOINT}`,
    topListSubclasses: {
      POST: `${config.apiUrl}${POWER_RANKING_ENDPOINT}${TOP_LIST_SUBCLASSES_SUBENDPOINT}`
    },
    topListGroups: {
      POST: `${config.apiUrl}${POWER_RANKING_ENDPOINT}${TOP_LIST_GROUPS_SUBENDPOINT}`
    },
    inProject: (projectId, country) => ({
      subclasses: {
        GET: `${
          config.apiUrl
        }${POWER_RANKING_ENDPOINT}/${projectId}${PROJECT_CPC_SUBCLASSES_SUBENDPOINT}${
          country ? '?country=' + country : ''
        }`
      },
      groups: {
        GET: `${
          config.apiUrl
        }${POWER_RANKING_ENDPOINT}/${projectId}${PROJECT_CPC_GROUPS_SUBENDPOINT}${
          country ? '?country=' + country : ''
        }`
      }
    })
  },
  cpcClassSummaries,
  cpcDescriptions: {
    POST: `${config.apiUrl}${CPC_DESCRIPTIONS}`
  },
  cpcDescription: (majorClass, firstLevel) => ({
    GET:
      `${config.apiUrl}${CPC_DESCRIPTION}/${majorClass}` +
      (firstLevel ? `?cpc_group=${firstLevel}` : '')
  }),
  comparisons,
  getProjectsForComparison,
  matchingRequestTargets: {
    GET: `${config.apiUrl}${MATCHING_REQUEST_TARGETS_ENDPOINT}`
  },
  matchingRequestCreate: {
    POST: `${config.apiUrl}${MATCHING_REQUEST_CREATE_ENDPOINT}`
  },
  matchingRequestResults: (active) => ({
    GET: RouteBuilder.build(`${config.apiUrl}${MATCHING_REQUESTS_RESULTS_ENDPOINT}`, {}, { active })
  }),
  matchingArchiveRequestResults: (id) => ({
    PUT: `${config.apiUrl}${MATCHING_ARCHIVE_REQUESTS_RESULTS_ENDPOINT}/${id}`
  }),
  matchingExecuteRequest: {
    POST: `${config.apiUrl}${MATCHING_EXECUTE_REQUEST_ENDPOINT}`
  },
  matchingRerunRequest: (id) => ({
    PUT: `${config.apiUrl}${MATCHING_RERUN_REQUEST_ENDPOINT}/${id}`
  }),
  matchingRequestDetails: (id) => ({
    GET: `${config.apiUrl}${MATCHING_REQUEST_DETAILS_ENDPOINT}/${id}`
  }),
  crawlSiteCreate: {
    POST: `${config.apiUrl}${CRAWL_SITE_CREATE_ENDPOINT}`
  },
  crawlSite: (id) => ({
    PUT: `${config.apiUrl}${CRAWL_SITE_ENDPOINT}/${id}`
  }),
  matchingRequestsById: (id) => ({
    GET: RouteBuilder.build(`${config.apiUrl}${MATCHING_REQUESTS_ENDPOINT}/${id}`, {})
  }),
  matchingClaimByUCIDRequest: (ucid, claimNo) => ({
    GET: RouteBuilder.build(
      `${config.apiUrl}${MATCHING_RESULT_BY_UCID}${ucid}/claims/${claimNo}`,
      {}
    )
  }),
  matchingPatentByUCIDRequest: (ucid) => ({
    GET: RouteBuilder.build(`${config.apiUrl}${MATCHING_RESULT_BY_UCID}${ucid}/elastic`, {})
  }),
  matchingResultsBySiteIds: () => ({
    POST: RouteBuilder.build(`${config.apiUrl}${MATCHING_REQUESTS_RESULTS_ENDPOINT}`, {})
  }),
  matchedResults: (id) => ({
    GET: RouteBuilder.build(`${config.apiUrl}${MATCHED_RESULTS_ENDPOINT}/${id}/results`, {})
  }),
  matchedResultsStatus: (id) => ({
    GET: RouteBuilder.build(`${config.apiUrl}${MATCHED_RESULTS_ENDPOINT}/${id}/status`, {})
  }),
  matchedHighlight: (id) => ({
    GET: RouteBuilder.build(`${config.apiUrl}${MATCHED_RESULTS_ENDPOINT}/${id}/matched`, {})
  }),
  matchedRating: (id) => ({
    POST: `${config.apiUrl}${MATCH_RATING_ENDPOINT}`,
    GET: RouteBuilder.build(
      `${config.apiUrl}${MATCH_RATING_ENDPOINT}/${id}`,
      {},
      { all_users: true }
    ),
    PUT: RouteBuilder.build(`${config.apiUrl}${MATCH_RATING_ENDPOINT}/${id}`, {}),
    DELETE: RouteBuilder.build(`${config.apiUrl}${MATCH_RATING_ENDPOINT}/${id}`, {})
  }),
  exports,
  reportCharts
};
