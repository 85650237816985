import { CURRENT_USER_KEY, SESSIONS_ENDPOINT } from '../constants/constants';
import config from '../conf/config';
import { jsonContentTypeHeader } from '../helpers/jsonContentTypeHeader';
import { authHeader } from '../helpers/authHeader';
import { endpoints } from '../constants/endpoints';

const login = async ({ email, password, stayIn }) => {
  console.log({ email, password }, JSON.stringify({ email, password }));

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password })
  };

  const response = await fetch(
    `https://api.dev.ipediadata.com${SESSIONS_ENDPOINT}`,
    requestOptions
  );

  if (!response.ok) {
    return Promise.reject(response);
  }

  const user = await response.json();

  const storage = stayIn ? window.localStorage : window.sessionStorage;
  storage.setItem(CURRENT_USER_KEY, JSON.stringify(user.data));
  return user.data;
};

const updatePassword = (oldPassword, newPassword, confirmNewPassword) => {
  const endpoint = endpoints.updatePassword;
  const method = 'PATCH';

  const requestOptions = {
    method,
    headers: jsonContentTypeHeader(authHeader()),
    body: JSON.stringify({
      user: {
        old_password: oldPassword,
        password: newPassword,
        password_confirmation: confirmNewPassword
      }
    })
  };

  return fetch(endpoint[method], requestOptions).then((response) => {
    if (!response.ok) {
      return Promise.reject(response.statusText);
    }
    return response;
  });
};

export const authenticationService = {
  login,
  updatePassword
};
