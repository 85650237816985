import { Outlet } from 'react-router';

import { Header } from '@components/Header';
import MainLogo from '@assets/images/logo_white.png';

export const PageLayout = () => {
  return (
    <div style={{ height: '100vh', maxHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Header />
      <Outlet />
    </div>
  );
};
