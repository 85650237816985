import { MarkerType } from 'reactflow';

const getEdgeStyle = (type) => {
  const styleMap = {
    division: {
      markerEnd: { type: MarkerType.ArrowClosed, color: 'blue' },
      style: {
        strokeWidth: 2,
        stroke: 'blue'
      },
      label: 'DIV'
    },
    continuation: {
      markerEnd: { type: MarkerType.ArrowClosed, color: '#222222' },
      style: {
        strokeWidth: 2,
        stroke: '#222222'
      },
      label: 'CON'
    }
  };

  return {
    ...styleMap[`${type}`]
  };
};

export const edgeTemplate = ({ id, source, target, label, rel }) => ({
  id,
  source,
  target,
  type: 'floating',
  sourceHandle: 'c',
  targetHandle: 'a',
  ...getEdgeStyle(rel)
});
