import { authenticationService } from '../services/authentificationService';

export const authHeader = (headers) => {
  // return authorization header with jwt token
  let result = headers ? headers : {};

  const currentUser = authenticationService.getAuthorized();
  if (currentUser && currentUser.token) {
    result['Authorization'] = currentUser.token;
  }

  return result;
};
