import { create } from 'zustand';
import { authenticationService } from '@services/authentificationService';

import { CURRENT_USER_KEY } from '../constants/constants';
const { login: signin, logout, getAuthorized, updatePassword } = authenticationService;

export const useUserAuth = create((set, get) => ({
  user: null,
  isAuthorized: null,
  error: null,
  loading: false,
  login: async ({ email, password, stayIn }) => {
    try {
      set({ loading: true });
      const data = await signin({ email, password, stayIn });
      set({ user: data, loading: false, isAuthorized: true });
    } catch (error) {
      console.log({ error });
      set({ error, loading: false });
    }
  },
  logout: () => {
    set({ user: null, isAuthorized: false });
    window.sessionStorage.removeItem(CURRENT_USER_KEY);
    window.localStorage.removeItem(CURRENT_USER_KEY);
  },
  authorize: () => {
    const data = JSON.parse(
      window.sessionStorage.getItem(CURRENT_USER_KEY) ||
        window.localStorage.getItem(CURRENT_USER_KEY)
    );

    set({ user: data, isAuthorized: !!data });
  }
}));
