// User Context Actions
export const LOGIN_ACTION = 'LOGIN';
export const LOGOUT_ACTION = 'LOGOUT';

// Session storage keys
export const CURRENT_USER_KEY = 'currentUser';

// API endpoints
export const SESSIONS_ENDPOINT = '/v1/sessions';

export const PROJECTS_ENDPOINT = '/v1/projects';
export const PROJECT_CHANGE_OWNER_SUBENDPOINT = '/change_owner';
export const PROJECT_ADD_MEMBER_SUBENDPOINT = '/add_member';
export const PROJECT_REMOVE_MEMBER_SUBENDPOINT = '/remove_member';
export const PROJECT_DOWNLOAD_MISSING_FAMILY_MEMBERS = '/missing_family_docs.xlsx';
export const PROJECT_REMOVE_PATENTS_SUBENDPOINT = '/remove_patents';
export const PROJECT_ARCHIVE_SUBENDPOINT = '/archive';
export const PROJECT_UNARCHIVE_SUBENDPOINT = '/unarchive';
export const PROJECT_PATENTS_SUBENDPOINT = '/patents';
export const PROJECT_FAMILIES_SUBENDPOINT = '/families';
export const PROJECT_FAMILIES_ALL_SUBENDPOINT = '/families_all';
export const PROJECT_IMPORT_REPORT_SUBENDPOINT = '/import_report';
export const PROJECT_COMPARISONS_SUBENDPOINT = '/comparisons';
export const PROJECT_TARGET_COMPARISONS_SUBENDPOINT = '/comparison_targets';
export const PROJECT_DYNAMIC_SUBPROJECTS_SUBENDPOINT = '/dynamic_subprojects';

export const PROJECT_CITE_ENDPOINT = '/cite_cos';
export const PROJECT_CITE_ASSIGNEE_DETAIL_SUBENDPOINT = '/detail';
export const PROJECT_CITE_DOWNLOAD_ENDPOINT = '/cites_download.xlsx';

export const ORG_USERS_ENDPOINT = '/v1/org_users';
export const USERS_ENDPOINT = '/v1/users';
export const UPDATE_PASSWORD_ENDPOINT = '/v1/users/update_password';
export const ORGANIZATIONS_ENDPOINT = '/v1/organizations';

export const IFI_SEARCH_ENDPOINT = '/v1/ifi_search';

export const IMPORT_JOBS_ENDPOINT = '/v1/import_jobs';
export const JOBS_LIST_SUBENDPOINT = '/list';
export const JOBS_LIST_DOWNLOAD_SUBENDPOINT = '/list.xlsx';
export const DYNAMIC_TAMPLATES_ENDPOINT = '/v1/dynamic_templates';

export const ELASTIC_SEARCH_ENDPOINT = '/v1/elasticsearch';
export const ELASTIC_MTERMVECTORS_ENDPOINT = '/v1/elasticsearch/mtermvectors';
export const ELASTIC_FAMILIES_ENDPOINT = '/v1/elasticsearch/project_families';

export const TARGETS_ENDPOINT = '/v1/targets';
export const PATENT_REVIEWS_ENDPOINT = '/v1/patent_reviews';
export const PATENT_REVIEW_TARGETS_ENDPOINT = '/v1/patent_review_targets';
export const PATENT_REVIEW_EOUS_ENDPOINT = '/v1/patent_review_eous';

export const PATENT__ENDPOINT = '/v1/patents/';
export const PATENT__PDF_SUBENDPOINT = '/pdf';
export const PATENT__CLAIMS_SUBENDPOINT = '/claims';
export const PATENT__SCORE_STATUS_SUBENDPOINT = '/score_status';

export const PATENT_DETAILS = '/v1/patent_details';
export const PATENT_DETAILS_BIBLIOGRAPHY = '/bibliography';
export const PATENT_DETAILS_SPECIFICATIONS = '/specification';
export const PATENT_DETAILS_FORWARD_CITES = '/forward_cites';
export const PATENT_DETAILS_REFERENCES = '/references';
export const PATENT_DETAILS_LEGAL_EVENTS = '/events';
export const PATENT_DETAILS_PATENT_FAMILY = '/patent_families';
export const PATENT_DETAILS_EXTENDED_FAMILY = '/extended_families';
export const PATENT_DETAILS_IMAGES = '/images';
export const PATENT_DETAILS_PTAB = '/ptab';
export const PATENT_DETAILS_ASSIGNEE_HISTORY = '/assignee_history';
export const PATENT_DETAILS_PATENT_BY_UCID = '/patent_by_ucid';
export const PATENT_DETAILS_SCORES = '/scores';
export const PATENT_DETAILS_CITES = '/cite_cos';
export const PATENT_LITIGATION = '/litigation';
export const PATENT_LITIGATION_INFO = '/litigation_info';

export const EXPORTS_ENDPOINT = '/v1/exports';
export const EXPORTS_DOWNLOAD_SUBENDPOINT = '/download';
export const PATENT_ART_TIMELINE_DATA_SUBENDPOINT = '/art_timeline';

export const POWER_RANKING_ENDPOINT = '/v1/power_ranking';
export const POWER_RANKING_CPC_CLASS_SUMMARIES_ENDPOINT = '/v1/cpc_class_summaries';
export const TOP_LIST_SUBCLASSES_SUBENDPOINT = '/top_list_subclasses';
export const TOP_LIST_GROUPS_SUBENDPOINT = '/top_list_groups';
export const PROJECT_CPC_SUBCLASSES_SUBENDPOINT = '/cpc_class';
export const PROJECT_CPC_GROUPS_SUBENDPOINT = '/cpc_group';

export const PROJECT_COMPARISONS_ENDPOINT = '/v1/project_comparisons';
export const PROJECT_COMPARISONS_PROJECT_ENDPOINT = '/v1/project_comparisons/projects';

export const MATCHING_REQUEST_TARGETS_ENDPOINT = '/v1/matching_request_targets';
export const MATCHING_REQUESTS_RESULTS_ENDPOINT = '/v1/matching_requests_results';
export const MATCHING_ARCHIVE_REQUESTS_RESULTS_ENDPOINT = '/v1/matching_requests_results';
export const MATCHING_EXECUTE_REQUEST_ENDPOINT = '/v1/matching';
export const MATCHING_RERUN_REQUEST_ENDPOINT = '/v1/matching';
export const MATCHING_REQUEST_DETAILS_ENDPOINT = '/v1/matching';
export const MATCHING_REQUEST_CREATE_ENDPOINT = '/v1/matching_request';

// For Matching Results Page
export const MATCHING_CRAWL_SITE_ID_ENDPOINT = '';
export const MATCHING_REQUESTS_ENDPOINT = '/v1/matching_requests_results';
export const MATCHING_RESULT_BY_UCID = '/v1/patents/';

export const MATCHED_RESULTS_ENDPOINT = '/v1/matching';
export const MATCHED_RESULTS_PROCESS_ENDPOINT = '/v1/matched_results_process';

export const MATCH_RATING_ENDPOINT = '/v1/matched_result_rating';

export const CRAWL_SITE_CREATE_ENDPOINT = '/v1/crawl_site';
export const CRAWL_SITE_ENDPOINT = '/v1/crawl_site';

export const CPC_DESCRIPTIONS = '/v1/cpc/list';
export const CPC_DESCRIPTION = '/v1/cpc';

// Http Error codes
export const ERROR_CODE_UNAUTHORIZED = 401;

// Elastic constants
export const ELASTIC_MAX_SIZE = 60000;

// Chart constants
export const FIVE_STAR = 5;

// Iframe hash
export const IFRAME_HASH = '#iframe';

export const REPORTS_ENDPOINT = '/v1/reports';
export const REPORT_PRODUCTS_COUNTS_SUBENDPOINT = '/products_counts';
export const REPORT_ASSERTION_COUNTS_SUBENDPOINT = '/assertion_counts';
export const REPORT_PROSECUTION_COUNTS_SUBENDPOINT = '/prosecution_counts';
export const REPORT_EOU_COUNTS_SUBENDPOINT = '/eou_counts';
export const REPORT_TARGETS_PRODUCTS_BAR_SUBENDPOINT = '/targets_products';
export const REPORT_FAMILIES_ASSERTION_COUNTS_SUBENDPOINT = '/families_assertion_counts';
