import { AppBar, Button } from '@mui/material';
import { Link } from 'react-router-dom';

import { useUserAuth } from 'stores/useUserAuth';

import './Header.css';

import Logo from '@assets/images/logo_white.png';

export const Header = () => {
  const { logout } = useUserAuth();

  return (
    <AppBar className="appBar" position="static">
      <Link to="/graph">
        <img
          src={Logo}
          alt="Logo"
          className="navbar-logo"
          style={{ height: '60px', padding: '10px' }}
        />
      </Link>
      <Button className="logout" onClick={() => logout()}>
        Logout
      </Button>
    </AppBar>
  );
};
