// eslint-disable-next-line no-unused-vars
const toBool = (value) => value === 'true' || value === true;

// .env.local file can be created with the same structure as .env file to override this values without commiting them

const config = {
  apiUrl:
    location.hostname === 'localhost' || !process.env.API_URL
      ? 'https://api.staging.ipediadata.com'
      : process.env.API_URL,
  isDev: process.env.IS_DEV ? process.env.IS_DEV === 'true' : true,
  patentDetailsUrl: '/patents/',
  patentsTemplateFileDownloadUrl: 'http://ipedia.dominionharbor.com/import_template.xlsx',
  patentsTemplateFileDownloadLocalUrl: '/src/assets/data/import_template.xlsx',
  buildLanguage: process.env.BUILD_LANGUAGE
};

export default config;
