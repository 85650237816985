import React, { useCallback, useState, useEffect } from 'react';

import {
  TextField,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Slide,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  TableBody,
  DialogActions,
  Box
} from '@mui/material';

import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  ConnectionMode
} from 'reactflow';

import InputMask from 'react-input-mask';

import dagre from 'dagre';

import SimpleFloatingEdge from './SimpleFloatingEdge';
import CustomNode from './CustomNode';

import { edgeTemplate } from './edgeTemplate';

import { useAssetsUSFamilyQuery, usePatentDataQuery } from '@queries';

import 'reactflow/dist/style.css';
import './Graph.css';

const uppercaseLetter = /^[a-z, A-Z]*$/;
const digit = /[0-9]/;
const optionalDigit = /^[0-9]?/;
const searchMask = [
  uppercaseLetter,
  uppercaseLetter,
  '-',
  digit,
  digit,
  digit,
  digit,
  digit,
  digit,
  digit,
  '-',
  uppercaseLetter,
  digit
];
const searchPlaceholder = 'US-7514089-B2';

const nodeWidth = 322;
const nodeHeight = 150;

const nodeTypes = {
  custom: CustomNode
};

const edgeTypes = {
  floating: SimpleFloatingEdge
};

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const getLayoutedElements = ({ nodes, edges, direction = 'TB' }) => {
  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction || 'TB' });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? 'left' : 'top';
    node.sourcePosition = isHorizontal ? 'right' : 'bottom';

    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2
    };

    return node;
  });

  return { nodes, edges };
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Graph = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const [reactFlowInstance, setReactFlowInstance] = useState(null);

  const [assetNumber, assetNumberSet] = useState(searchPlaceholder);

  const [patentDetailsNumber, setPatentdetailsNumber] = useState(null);

  const [dialogOpen, setDialogOpen] = useState(false);

  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

  const fitViewOptions = { padding: 4 };

  const {
    isLoading: isPatentDataLoading,
    data: patentDetailsData,
    error: patentDetailsError,
    refetch: patentDetailsRefetch,
    fetchStatus: patentDetailsFetchStatus
  } = usePatentDataQuery(patentDetailsNumber);

  const {
    isLoading: loading,
    data: assetData,
    error,
    refetch,
    fetchStatus
  } = useAssetsUSFamilyQuery(assetNumber);

  const fetchAssetUSFamily = () => {
    refetch();
  };

  const fetchPatentDetails = () => {
    patentDetailsRefetch();
  };

  const onLayout = useCallback(
    ({ newNodes, newEdges, direction }) => {
      const { nodes: layoutedN, edges: layoutedE } = getLayoutedElements({
        nodes: newNodes,
        edges: newEdges,
        direction
      });

      setNodes([...layoutedN]);
      setEdges([...layoutedE]);
    },
    [nodes, edges]
  );

  const showLoader = fetchStatus === 'fetching';
  const showPatentLoader = patentDetailsFetchStatus === 'fetching';

  useEffect(() => {
    const data = assetData?.parents;

    if (!data) return;

    const newNodes = data.map((x) => ({
      id: x.ucid,
      data: {
        label: x.ucid,
        title: x.title,
        file: x.file_date,
        applicationNumber: x.application_number,
        onClick: (e) => {
          e.preventDefault();
          e.stopPropagation();
          setPatentdetailsNumber(x.ucid);
          setDialogOpen(true);
        }
      },
      type: 'custom',
      position: { x: 0, y: 0 }
    }));

    const newEdges = data
      .filter((x) => !!x.children)
      .map((x) => {
        const children = x.children;

        if (!children.length) return;

        return children.map((y) => {
          return edgeTemplate({
            id: `e${x.ucid}-${y.ucid}`,
            source: x.ucid,
            target: y.ucid,
            label: y.relationship,
            rel: y.relationship
          });
        });
      })
      .flat()
      .filter((x) => !!x);

    onLayout({ direction: null, newNodes, newEdges });
  }, [assetData]);

  useEffect(() => {
    if (!patentDetailsNumber) return;

    fetchPatentDetails();
  }, [patentDetailsNumber]);

  useEffect(() => {
    if (!reactFlowInstance) return;
    reactFlowInstance.fitView();
  }, [assetData, reactFlowInstance]);

  const getTableLayout = useCallback(() => {
    if (!patentDetailsData) return null;
    const keys = [
      { name: 'Title', key: 'title' },
      { name: 'Publication No', key: 'publication_id' },
      { name: 'Application No', key: 'application_number' },
      { name: 'Grant Number ', key: 'grant_number' },
      { name: 'Priority Date', key: 'priority_date' },
      { name: 'File Date', key: 'file_date' },
      { name: 'Publication Date', key: 'publication_date' },
      { name: 'Expiration Date', key: 'expiration_date' },
      { name: 'PQ', key: 'pq_score' },
      { name: 'IV', key: 'iv_score' },
      { name: 'IPedia Index', key: 'ipedia_index' },
      { name: 'Current Assignee', key: 'current_assignee' },
      { name: 'Original Assignee', key: 'orginal_assignee' },
      { name: 'Claim 1', key: 'claim1' }
    ];

    const data = {
      ...patentDetailsData,
      ...assetData.parents.find((x) => x.ucid === patentDetailsData.ucid)
    };

    return (
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table>
          <TableBody>
            {keys.map((k, i) => {
              if (!data[k.key]) return null;

              return (
                <TableRow key={k.key}>
                  <TableCell>{k.name}</TableCell>
                  <TableCell>{data[k.key]}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  });

  return (
    <>
      <form
        className="search"
        style={{
          display: 'flex',
          justifyContent: 'center',
          minWidth: '500px',
          maxWidth: '80%',
          margin: '20px auto'
        }}
        onSubmit={(e) => {
          e.preventDefault();
          fetchAssetUSFamily();
        }}>
        <InputMask
          value={assetNumber.toUpperCase()}
          onChange={(e) => {
            assetNumberSet(e.target.value);
          }}
          mask={searchMask}
          maskPlaceholder={null}>
          <TextField
            disableUnderline
            placeholder="Enter patent number"
            label="Format: AA-9999999-A9"
          />
        </InputMask>

        <Button type="submit" variant="contained" disabled={!assetNumber.length}>
          Search
        </Button>
      </form>
      <div
        className="graph-container"
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {!nodes.length && !showLoader && (
          <Box sx={{ typography: 'title', fontSize: 24 }}>
            {`Please, type in a patent number and press "Search"`}
          </Box>
        )}
        {showLoader && <CircularProgress />}
        {!!nodes.length && (
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            edgeTypes={edgeTypes}
            nodeTypes={nodeTypes}
            onInit={(instance) => setReactFlowInstance(instance)}
            fitView
            fitViewOptions={fitViewOptions}
            connectionMode={ConnectionMode.Loose}>
            <MiniMap />
            <Controls />
            <Background />
          </ReactFlow>
        )}
        <Dialog
          open={dialogOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setDialogOpen(false)}
          aria-describedby="patent details dialog">
          <DialogTitle>{`Showing details for patent ${patentDetailsNumber}`}</DialogTitle>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            {showPatentLoader ? <CircularProgress /> : getTableLayout()}
          </Box>
          <DialogActions>
            <Button onClick={() => setDialogOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default Graph;
